import { useEffect, useState } from "react";
import CloseIcon from "../../assets/images/close.png";

import API from "../../api";
import "./Winner.scss";
import MainLayout from "../../layouts/MainLayout";
import { WinnerDetailsResponse, WinnerListResponse } from "../../interface/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ROUTES } from "../../lib/consts"; // Assuming you have routes defined here
import { useNavigate } from "react-router-dom";


const WinnerPage = () => {
  const [winners, setWinners] = useState<{
    id: string;
    name: string;
    mobile: string;
    state: string;
    city: string;
    pincode: string;
  }[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);

  // Check if userKey is available in the state
  const userKey = useSelector((state: any) => state.authReducer.userKey);
  const navigate = useNavigate();


  useEffect(() => {
    if (!userKey) {
      return; // Avoid fetching data if no userKey is present
    }
    fetchWinnerDateList(); // Fetch the winner date list on component mount
  }, [userKey]);

  const fetchWinnerDateList = () => {
    setLoading(true);
    API.getWinnerDateList() // Call without parameters if it doesn't accept any
      .then((response: WinnerListResponse) => {
        const { startDate: start, endDate: end } = response;
        if (start) {
          setStartDate(new Date(start));
        }
        if (end) {
          setEndDate(new Date(end));
          setSelectedDate(new Date(end));
        }
      })
      .catch((error) => {
        console.error("Error fetching winner date list", error);
      })
      .finally(() => setLoading(false));
    };

  const fetchWinnersList = (date: Date) => {
    setLoading(true);
    const formattedDate = date.toISOString().split("T")[0];
    API.getWinnersList({ userKey, date: formattedDate })
      .then((response: WinnerDetailsResponse) => {
        if (response.winnerData) {
          const winnersWithId = response.winnerData.map((winner, index) => ({
            id: `${index}`,
            name: winner.name,
            mobile: winner.mobile,
            state: winner.state,
            city: winner.city,
            pincode: winner.pincode,
          }));
          setWinners(winnersWithId);
        } else {
          console.error("Unexpected winners response structure:", response);
        }
      })
      .catch((error) => {
        console.error("Error fetching winners list", error);
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (selectedDate) {
      fetchWinnersList(selectedDate); // Fetch winners when selected date changes
    }
  }, [selectedDate]);

  // Redirect to "Not Allowed" page if userKey is missing
  // if (!userKey) {
  //   return <Navigate to={ROUTES.NOT_ALLOWED} />;
  // }

  return (
    <MainLayout className="winner-page">
      <img
            src={`${process.env.REACT_APP_ASSETS_BASE_PATH}/images/close.png`}

            alt="Popup close"
            className="closeBtn"
            onClick={() => {
          //     hideModal();
          //     props.onClose && props.onClose();
          navigate(ROUTES.NOT_ALLOWED)

            }}
          />
      <div className="winner-page" style={{marginTop:"2rem"}}>
        {selectedDate ? (
          <div>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
              minDate={startDate || undefined} // Ensure valid types
              maxDate={endDate || undefined} // Ensure valid types
            />
          </div>
        ) : null}
        <h3 style={{ marginBottom: "0" }}>Winners list</h3>
        {loading && <p>Loading...</p>}
        {selectedDate && (
          <p className="date">({selectedDate.toISOString().split("T")[0]})</p>
        )}
        {/* <p className="header-txt">Please submit your address details on WhatsApp within 5 days or you will be disqualified from being a winner.</p> */}
        {winners.length > 0 ? (
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th>SI No</th>
                  <th>Name</th>
                  <th>Mobile</th>
                  <th>City</th>
                  <th>State</th>
                </tr>
              </thead>
              <tbody>
                {winners.map((winner, index) => (
                  <tr key={winner.id}>
                    <td>{index + 1}</td>
                    <td>{winner.name}</td>
                    <td>{winner.mobile}</td>
                    <td>{winner.city}</td>
                    <td>{winner.state}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p>No winners available for this date.</p>
        )}
      </div>
    </MainLayout>
  );
};

export default WinnerPage;
