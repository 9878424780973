import { useMemo } from "react";
import { LANGUAGE, LANGUAGES, ROUTES } from "../../lib/consts";
import "./SideMenu.scss";
import { useNavigate } from "react-router-dom";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import { useTranslation } from "react-i18next";

const SideMenu = ({
  open,
  onClose,
}: {
  open: boolean;
  onClose: () => void;
}) => {
  const navigate = useNavigate();

  const { showModal } = useGlobalModalContext();
  const { t, i18n } = useTranslation();

  const menuOptions = useMemo(
    () => [
      {
        name: ("Daily winners"),
        route: ROUTES.REGISTER,
        onClick: () => {
          navigate(ROUTES.WINNER)
          onClose();
        },
      },
      // {
      //   name: ("FAQ`S"),
      //   route: ROUTES.REGISTER,
      //   onClick: () => {
      //     showModal(MODAL_TYPES.PRVACY);
      //     onClose();
      //   },
      // },
      // {
      //   name: ("How to Participate"),
      //   route: ROUTES.REGISTER,
      //   onClick: () => {
      //     showModal(MODAL_TYPES.HOWTOREDEEM);
      //     onClose();
      //   },
      // },
      // {
      //   name: ("Terms & Conditions"),
      //   route: ROUTES.REGISTER,
      //   onClick: () => {
      //     showModal(MODAL_TYPES.TERMS);
      //     onClose();
      //   },
      // },
      // {
      //   name: t("sideMenu.contactUs"),
      //   route: ROUTES.REGISTER,
      //   onClick: () => {
      //     showModal(MODAL_TYPES.CONTACT_US);
      //     onClose();
      //   },
      // },
      // {
      //   name: "Privacy Policy",
      //   route: ROUTES.REGISTER,

      //   // onClick: () => {
      //   //   window.open("https://www.cargill.com/privacy", "_blank");
      //   // },
      // },


    ],
    [t]
  );
  return (
    <div className={`side-menu ${open ? "opened" : "closed"}`}>
      <div className="bg" onClick={onClose}></div>
      <div className="modal">
        <img
          src={`${process.env.REACT_APP_ASSETS_BASE_PATH}/images/banner.png`}
          alt="logo"
          // onClick={() => navigate(ROUTES.REGISTER)}
          className="logo"
        />
        <div className="options">
          {menuOptions.map((item) => (
            <div
              className="option"
              onClick={() => item.onClick && item.onClick()}
              key={item.name}
            >
              {item.name}
            </div>
          ))}
        </div>
        {/*<div className="language-option">
          <p className="title">{t("sideMenu.language")}</p>
          <select
            className="language-selection header-lang"
            onChange={(e) => {
              i18n.changeLanguage(e.target.value);
            }}
          >
            {Object.keys(LANGUAGES).map((key) => {
              if (i18n.language === key) {
                return (
                  <option key={key} value={key} selected>
                    {LANGUAGES[key as LANGUAGE]}
                  </option>
                );
              }

              return (
                <option key={key} value={key}>
                  {LANGUAGES[key as LANGUAGE]}
                </option>
              );
            })}
          </select>
          </div>*/}
      </div>
    </div>
  );
};

export default SideMenu;
