import MainLayout from "../../layouts/MainLayout";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import "./Register.scss";
import "../Notallowed/NotAllowed.scss"
import { useEffect, useRef, useState } from "react";
import API from "../../api";
import { ERROR_IDS } from "../../api/utils";
import { useNavigate, useLocation } from "react-router-dom";
import { LANGUAGE, LANGUAGES, ROUTES, STATES } from "../../lib/consts";
import store from "../../store/store";
import { setAccessToken, setRewards } from "../../store/actions/authAction";
import { useTranslation } from "react-i18next";
import { MODAL_TYPES, useGlobalModalContext } from "../../helpers/GlobalModal";
import { useAuthentication } from "../../hooks/useAuthentication";
import {
  hideCaptchaBadge,
} from "../../lib/utils";
import { setUserIdentification } from "../../store/actions/authAction";
import { gtagTrackEvent, GA_EVENTS } from "../../lib/utils";
import arrow from "../../assets/images/logos_whatsapp-icon.png";
declare var grecaptcha: any;

type StateResponseProps = {
  state: string;
};

const hardcodedStates = [
  { state: "Telangana" },
  { state: "Orissa" },
  { state: "Karnataka" },
  { state: "Chhattisgarh" },
  { state: "Andhra Pradesh" },
  { state: "Other" },
  // Add more states as needed
];

const Register = () => {
  const [isAgreed, setIsAgreed] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkboxError, setCheckboxError] = useState("");
  const { showModal } = useGlobalModalContext();
  const [resendTimer, setResendTimer] = useState(60);
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showStateDropdown, setShowStateDropdown] = useState(false);
  const [filteredCities, setFilteredCities] = useState<StateResponseProps[]>(
    []
  );
  const [filteredNewStates, setFilteredNewStates] = useState<
    StateResponseProps[]
  >([]);
  const [states, setStates] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const { isLoggedIn } = useAuthentication();
  const navigate = useNavigate();
  const location = useLocation();

  const timer = useRef<any | null>(null);

  const { t } = useTranslation();
  const handleCheckboxChange = () => {
    setIsAgreed(!isAgreed);
    setCheckboxError("");
  };

  useEffect(() => {
    setFilteredNewStates(filteredCities);
  }, [filteredCities]);

  useEffect(() => {
    {
      setFilteredCities(hardcodedStates);
    }
  }, []);

  useEffect(() => {
  }, [showStateDropdown]);

  const handleStateSelect = (selectedState: string) => {
    // console.log("modal clicke", selectedState);

    setStates(selectedState);
    setSelectedState(selectedState);
    setShowStateDropdown(false);
  };

  const RegisterValidation = Yup.object().shape({
    name: Yup.string()
      .required(t("registerPage.errors.invalidName") || "Enter valid name")
      .matches(
        /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
        t("registerPage.errors.invalidName") || "Enter valid name"
      ),
    mobile: Yup.string()
      .required(
        t("registerPage.errors.invalidMobile") ||
        "*Please enter a valid 10-digit number"
      )
      .matches(
        /^[56789][0-9]{9}$/,
        t("registerPage.errors.invalidMobile") ||
        "*Please enter a valid 10-digit number"
      ),
    city: Yup.string().required("Enter valid City")
      .matches(
        /^[a-zA-Z]{1}[a-zA-Z\s]{2,}$/,
        "Enter valid name"
      ),

    state: Yup.string().required(
      t("registerPage.errors.invalidstate") || "*Please enter a valid State"
    ),
    pincode: Yup.string().required(
      "*That’s not a valid code. Please enter valid Pincode"
    ),

    agreeTnc: Yup.boolean().oneOf(
      [true],
      "Please accept the terms and conditions"
    ),
  });

  const OtpValidation = Yup.object().shape({
    otp: Yup.string()
      .required(
        t("registerPage.errors.invalidOtp") || "*Please enter valid OTP"
      )
      .matches(
        /^[0-9]{6}$/,
        t("registerPage.errors.incorrectOtp") ||
        "*You have entered an incorrect OTP. Please try again"
      ),
  });

  const startTimer = () => {
    if (timer) clearInterval(timer.current);
    let time = 60;
    setResendTimer(time);
    timer.current = setInterval(() => {
      time--;
      setResendTimer(time);
      if (time <= 0) {
        clearInterval(timer.current);
      }
    }, 1000);
  };

  const resendOtp = () => {
    if (resendTimer <= 0) {
      startTimer();
      API.resendOTP().catch((err) => {
        console.log(err);
      });
    }
  };

  useEffect(
    () => () => {
      if (timer.current) {
        clearInterval(timer.current);
      }
    },
    []
  );

  function renderRegisterForm() {
    return (
      <Formik
        key="register-form"
        initialValues={{
          name: "",
          mobile: "",
          city: "",
          pincode: "",
          email: "",
          state: states,
          agreeTnc: false,
        }}
        validationSchema={RegisterValidation}
        onSubmit={(values, errors) => {
          console.log(errors, values);
          // setShowOtpForm(true);
          if (values.agreeTnc) {
            API.register(values).then(() => {
              // gtagTrackEvent(GA_EVENTS.Registered);
              setShowOtpForm(true);
              startTimer();
            }
            )
              .catch((err) => {
                const { messageId } = err;
                switch (messageId) {
                  case ERROR_IDS.INVALID_NAME:
                    errors.setErrors({
                      name:
                        "Please enter a valid name",
                    });
                    break;
                  case ERROR_IDS.INVALID_MOBILE:
                    errors.setErrors({
                      mobile:
                        "Please enter a valid number",
                    });
                    break;
                  case ERROR_IDS.INVALID_CITY:
                    errors.setErrors({
                      city:
                        "Please enter a valid city",
                    });
                    break;
                  case ERROR_IDS.INVALID_PINCODE:
                    errors.setErrors({
                      pincode:
                        "Please enter a valid pincode",
                    });
                    break;
                  case ERROR_IDS.INVALID_STATE:
                    errors.setErrors({
                      state:
                        "Please select a valid state",
                    });
                    break;
                  case ERROR_IDS.INVALID_CONSENT:
                    errors.setErrors({
                      agreeTnc:
                        "agree to terms and conditions",
                    });
                    break;
                  case ERROR_IDS.INVALID_CODE:
                    navigate(ROUTES.NOT_ALLOWED);
                    break;
                  case "codeAlreadyUsed":
                  case ERROR_IDS.USED_CODE:
                    navigate(ROUTES.SCANNED);
                    break;
                  default:
                    errors.setErrors({
                      agreeTnc: err.message,
                    });
                    break;
                }
              });
          } else {
            setCheckboxError("Please accept the terms and conditions");
            setShowError(true); // Optionally, show an error message
          }
          gtagTrackEvent(GA_EVENTS.Submit_click);
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
          setFieldValue,
        }) => {
          return (
            <Form
              onSubmit={handleSubmit}
              onClick={() => setShowStateDropdown(false)}
            >

              <div className="participated-form">
                <p className="scan-text">Bumper prize winners will be declared soon
                <br/>Stay Tuned !! </p>
              </div>


            </Form>
          );
        }}
      </Formik>
    );
  }

  function renderOtpForm() {
    return (
      <Formik
        key="otp-form"
        initialValues={{
          otp: "",
        }}
        validationSchema={OtpValidation}
        onSubmit={(values, errors) => {
          // navigate(ROUTES.COMPLETED);

          // grecaptcha
          //   .execute(process.env.REACT_APP_CAPTCHA_SITE_KEY, {
          //     action: "verify_otp",
          //   })
          //   .then((token: string) => {
          API.verifyOTP(values.otp)
            .then((response) => {
              hideCaptchaBadge();
              // debugger;

              store.dispatch(setRewards({
                myGlamCode: response.myGlamCode,
                isCashbackWinner: response.isCashbackWinner,
                cashbackRewardCode: response.cashbackRewardCode
              }));
              // gtagTrackEvent(GA_EVENTS.Verify_OTP);
              if (response.fromPuducherry === true) {
                navigate(ROUTES.THANK_YOU);
              } else {
                navigate(ROUTES.COMPLETED);
              }
            })
            .catch((err) => {
              const { messageId } = err;
              switch (messageId) {
                case ERROR_IDS.INVALID_OTP:
                  errors.setErrors({
                    otp:
                      t("registerPage.errors.invalidOtp") ||
                      "Please enter a valid OTP",
                  });
                  break;
                case "codeAlreadyUsed":
                  navigate(ROUTES.SCANNED);
                  break;
                default:
                  errors.setErrors({
                    otp: err.message,
                  });
                  break;
              }
            });
          // });
        }}
      >
        {({
          values,
          handleChange,
          handleSubmit,
          handleBlur,
          errors,
          touched,
        }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <div className="otp-form">
                <p className="form-title otp">Enter OTP</p>
                <div className="input-group">
                  <input
                    autoComplete="off"
                    type="tel"
                    onChange={handleChange}
                    value={values.otp}
                    name="otp"
                    maxLength={6}
                    onBlur={handleBlur}
                    placeholder={t("registerPage.placeholder.otp")}
                  />
                </div>
                {errors.otp && touched.otp && (
                  <p className="error">{errors.otp}</p>
                )}
                <div className="flex-center timer-wrapper">
                  {resendTimer > 0 && (
                    <div className="timer flex-center">{resendTimer}</div>
                  )}
                  <span>{t("registerPage.didNotReceive")}</span>
                  <span className="link" onClick={resendOtp}>
                    {t("registerPage.resend")}
                  </span>
                </div>
                <button className="btn btn-primary" type="submit">
                  Submit
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  return (
    <MainLayout className="register-page">
      {showOtpForm ? renderOtpForm() : renderRegisterForm()}
    </MainLayout>
  );
};

export default Register;
