import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import API from './api';
import { useGlobalLoaderContext } from './helpers/GlobalLoader';
import store from "./store/store";
import { setUserIdentification } from './store/actions/authAction';
import { useAuthentication } from './hooks/useAuthentication';
import { logoutUser } from './lib/utils';
import { toast } from 'react-toastify';
import { ROUTES } from './lib/consts';
import { useNavigate, useLocation } from "react-router-dom";



function QRComponent() {
    // Extract the "id" from the URL
    const { showLoader, hideLoader } = useGlobalLoaderContext();
    const { isLoggedIn } = useAuthentication();
    const navigate = useNavigate();


    const { id } = useParams();

    // {
    //     useEffect(() => {
    //         API.initialize(showLoader, hideLoader);
    //         if (id) {

    //             API.createUser(id).then((response: any) => {
    //                 // console.log(response);
    //                 store.dispatch(setUserIdentification(response));
    //                 if (!response.isLoggedIn && isLoggedIn) {
    //                     logoutUser();
    //                     toast.info("Your session has been expired");
    //                 }


    //                 // addCaptchaScript();
    //             }).catch(() => {
    //                 toast.info("invalid code");

    //             });
    //         }else{
    //             navigate(ROUTES.COMPLETED);
    //         }
    //     }, []);
    // }



    {
        useEffect(() => {
            API.initialize(showLoader, hideLoader);
            if (id) {

                API.createUser(id).then((response) => {
                    // console.log(response);
                    store.dispatch(setUserIdentification(response));
                    if (!response.isLoggedIn && isLoggedIn) {
                        logoutUser();
                        toast.info("Your session has been expired");
                    }
                    if (response.isAlreadyScanned) {
                        navigate(ROUTES.NOT_ALLOWED);
                    }
                    else if (response.isValidCode) {
                        navigate(ROUTES.NOT_ALLOWED);
                    }
                    
                     else {
                        navigate(ROUTES.NOT_ALLOWED);
                    }
                    // addCaptchaScript();
                }).catch(() => {
                    toast.info("invalid code");
                    navigate(ROUTES.NOT_ALLOWED);
                });
            } else {
                navigate(ROUTES.NOT_ALLOWED);
                console.log("notallowed");

            }
        }, []);
    }

    return (
        <></>
    );
}

export default QRComponent;